import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 16 18",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "8",
      cy: "9.375",
      r: "8",
      fill: "black"
    }, null, -1),
    _createElementVNode("circle", {
      cx: "8",
      cy: "8.625",
      r: "8",
      fill: "#0081C7"
    }, null, -1),
    _createElementVNode("path", {
      d: "M7.992 0.625C3.576 0.625 0 4.209 0 8.625C0 13.041 3.576 16.625 7.992 16.625C12.416 16.625 16 13.041 16 8.625C16 4.209 12.416 0.625 7.992 0.625ZM10.576 12.937L8 11.385L5.424 12.937C5.12 13.121 4.744 12.849 4.824 12.505L5.504 9.577L3.24 7.617C2.976 7.385 3.12 6.945 3.472 6.913L6.464 6.657L7.632 3.897C7.768 3.569 8.232 3.569 8.368 3.897L9.536 6.649L12.528 6.905C12.88 6.937 13.024 7.377 12.752 7.609L11.62 8.589L10.488 9.569L11.168 12.505C11.248 12.849 10.88 13.121 10.576 12.937Z",
      fill: "#CCF5FF"
    }, null, -1),
    _createElementVNode("circle", {
      cx: "8",
      cy: "8.625",
      r: "7.665",
      stroke: "black",
      "stroke-width": "1"
    }, null, -1)
  ])))
}
export default { render: render }